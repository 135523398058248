import { Videos } from '../_app/cuchillo/components/Videos';
import { isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Cmp from '../windows/Cmp';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { GetBy } from '../_app/cuchillo/core/Element';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Default extends Page {
  eyesHolder = GetBy.selector(".eyes")[0];
  eyes = GetBy.selector(".eyes svg")[1];
  progress = {x:0, y:0}
  tick = 0;
  tickLimit = 100;

  constructor() {
    super();

    Videos.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
    });

    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
   
      super.loop();

      const box = this.eyesHolder.getBoundingClientRect();
        const position = {
          x: Interaction.positions.mouse.x - (box.left + box.width * .5),
          y: Interaction.positions.mouse.y - (box.top + box.height * .5),
        } 
        const offset = box.width * .10;

      if(!isSmartphone) {
        

        /*const progress = {
          x: Math.max(-1, Math.min(1, Interaction.positions.mouse.x/Metrics.CENTER_X)),
          y: Math.max(-1, Math.min(1, Interaction.positions.mouse.y/position.y)),
        }*/

        this.progress = {
          x: Math.min(1, Maths.normalize(box.width * 5, 0, position.x)),
          y: Math.min(1, Maths.normalize(box.width * 3, 0, position.y)),
        }
      } else {
        this.tick++;

        if(this.tick >= this.tickLimit) {
          this.tick = 0;
          this.tickLimit = Maths.maxminRandom(20, 10) * 10;

          this.progress = {
            x: Maths.maxminRandom(2, -2) * .5,
            y: Maths.maxminRandom(2, -2) * .5
          } 
        }
      }

          

      //console.log(Interaction.positions.mouse.y,Metrics.HEIGHT)

      this.eyes.style.transform = CSS.translate3D(offset * this.progress.x, offset * this.progress.y, 1);

   
  }
}

ControllerPage._addPage('default', Default);
